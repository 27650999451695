import React from 'react';
import { styled } from 'linaria/react';
import { useRouteMatch } from 'react-router-dom';
import t from '@jetshop/intl';
import { useQuery } from 'react-apollo';
import { css, cx } from 'linaria';
import { useIntl } from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from '../../Theme';
import { pseudoCaretCSS } from '../../Theme/ThemeClasses';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import CloseButton from '../../ui/CloseButton';

const StyledCloseButton = styled(CloseButton)`
  margin-left: 0.5rem;
  margin-right: auto;
`;

const Title = styled('h1')`
  text-align: center;
  position: absolute;
  font-size: 16px;
  font-weight: normal;
`;

const NavBarHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: 68px;
  border-bottom: 1px solid ${theme.colors.borderGrey};

  ${theme.above.md} {
    height: ${theme.elementSizes.headerHeightDesktop};
  }
`;

const menuInnerPadding = '1.5rem';

const menuCSS = css`
  /* margin-top: ${theme.space[2]}; */
  justify-content: space-between;
  padding-bottom: 6rem;
  /* overflow-x: hidden; */
  background: ${theme.colors.menu.background};
  color: ${theme.colors.menu.item};

  &,
  ul {
    display: flex;
    flex-direction: column;
  }

  &__inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
`;

const subCategoryCSS = css`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  overflow-y: auto;
  background: ${theme.colors.menu.subLevelBackground};
  transition: transform cubic-bezier(0, 0.2, 1, 0.8) 0.125s;
  will-change: transform;
  pointer-events: none;
  z-index: 1;

  &--open {
    transform: translateX(0%);
    overflow: hidden;
    pointer-events: auto;
  }

  &__inner {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    a {
      display: flex;
      align-items: center;
    }

    li:first-child {
      a {
        text-decoration: underline;
      }
    }
  }
`;

const menuItemCSS = css`
  display: inline-block;
  width: 100%;
  padding: 0 ${menuInnerPadding};
  position: relative;
  background: transparent;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  color: currentColor;
  transition: background ease-in-out 0.2s;
  border-bottom: 1px solid ${theme.colors.borderGrey};
  height: 62px;

  ${theme.above.md} {
    height: 81px;
  }

  &:hover {
    background: ${theme.colors.menu.itemBackgroundActive};
  }

  &__label {
    &:after {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translate(-50%, -50%) scale(0.9);
    }
  }

  &--back-button {
    span {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
    }

    ${theme.above.md} {
      height: ${theme.elementSizes.headerHeightDesktop};
    }
  }

  &__icon-wrapper-inner {
    margin-right: ${theme.space[2]};
  }
`;

const subLevelCategoryLabelIntl = 'Category menu {categoryName}';

const SubLevelEntry = ({ category, closeMenu, t }) => {
  const subLevelState = React.useState(false);
  const matches = useRouteMatch(category.primaryRoute.path);

  return (
    <>
      <button
        className={cx(
          menuItemCSS,
          matches || subLevelState[0] ? 'active' : null
        )}
        aria-controls={t(subLevelCategoryLabelIntl, {
          categoryName: category.name
        })}
        aria-expanded={subLevelState[0]}
        onClick={() => subLevelState[1](!subLevelState[0])}
      >
        <span className={cx(`${menuItemCSS}__label`, pseudoCaretCSS)}>
          {category.name}
        </span>
      </button>
      <SubLevelCategory
        category={category}
        closeMenu={closeMenu}
        subLevelState={subLevelState}
        t={t}
      />
    </>
  );
};

const SubLevelCategory = ({ category, subLevelState, closeMenu, t }) => {
  return (
    <div
      className={cx(
        subCategoryCSS,
        subLevelState[0] ? `${subCategoryCSS}--open` : null
      )}
      aria-label={t(subLevelCategoryLabelIntl, { categoryName: category.name })}
      aria-hidden={!subLevelState[0]}
    >
      <div className={`${subCategoryCSS}__inner`}>
        <button
          className={cx(
            menuItemCSS,
            `${menuItemCSS}--back-button`,
            pseudoCaretCSS
          )}
          onClick={() => subLevelState[1](!subLevelState[0])}
          aria-label={t('Go to previous menu level')}
        >
          <span>{category.name}</span>
        </button>
        <ul>
          <li>
            <CategoryLink
              className={menuItemCSS}
              onClick={closeMenu}
              category={category}
              t={t}
            >
              {t('Everything in {categoryName}', {
                categoryName: category.name
              })}
            </CategoryLink>
          </li>
          {category?.subcategories?.map(category => (
            <li key={category.id}>
              <MenuItem
                key={category.id}
                category={category}
                closeMenu={closeMenu}
                t={t}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const MenuItem = ({ category, closeMenu, t }) => (
  <>
    {category?.hasSubcategories ? (
      <SubLevelEntry category={category} closeMenu={closeMenu} t={t}>
        {category.name}
      </SubLevelEntry>
    ) : (
      <CategoryLink
        className={menuItemCSS}
        onClick={closeMenu}
        category={category}
        t={t}
      />
    )}
  </>
);

const CategoryTree = ({ closeMenu, openMenu }) => {
  const t = useIntl();
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 2
    },
    skip: openMenu !== true
  });

  if (result?.loading) return null;

  const topLevelCategories = result?.data?.categories.filter(
    category => category.level === 1
  );

  return topLevelCategories ? (
    <ul>
      {topLevelCategories.map(category => (
        <li key={category.id}>
          <MenuItem category={category} closeMenu={closeMenu} t={t} />
        </li>
      ))}
    </ul>
  ) : null;
};

const displayMenuTimeout = 400; // Drawer animation + 100ms

const handleCloseMenu = (closeDrawer, setDisplayMenu) => {
  closeDrawer();
  setTimeout(() => {
    setDisplayMenu(false);
  }, displayMenuTimeout);
};

const MobileMenu = () => {
  // const [displayMenu, setDisplayMenu] = React.useState(true);

  // React.useEffect(() => {
  //   if (displayMenu === false) {
  //     setTimeout(() => {
  //       setDisplayMenu(true);
  //     }, displayMenuTimeout);
  //   }
  // }, [displayMenu]);

  // return !displayMenu ? null : (
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen} size={445}>
          <div className={menuCSS}>
            <div className={`${menuCSS}__inner`}>
              <NavBarHeader>
                <StyledCloseButton onClick={drawer.hideTarget} />
                <Title>{t('Our products')}</Title>
              </NavBarHeader>
              <CategoryTree
                closeMenu={() => handleCloseMenu(drawer.hideTarget)}
                openMenu={drawer.isOpen}
              />
            </div>
          </div>
        </Drawer>
      )}
    </DrawerTarget>
  );
};

export default MobileMenu;
