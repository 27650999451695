/* Theme colors variants */
const colorVariants = {
  red: '#FF0000',
  green: '#408C51',
  grey200: '#F3F3F3',
  grey400: '#C6C6C6',
  grey600: '#D9D9D9',
  grey800: '#808080'
};

/* Jetshop Flight default colors */
const colors = {
  white: '#FFFFFF',
  black: '#000000',
  main: '#000000',
  grey: '#878787',
  darkerGrey: '#767676',
  lightgrey: '#E8E8E8',
  tablegrey: '#F3F3F3',
  beige: '#f5f5dc',
  loadingBar: '#2f80ed',
  blue: '#146DE1',
  red: '#EB0000',
  background: '#f7f7f7',
  darkgrey: '#333',
  mediumgrey: '#9a9a9a',
  highlight: '#2f80ed',
  ...colorVariants
};

/* Theme colors */
const themeColors = {
  primary: colors.red,
  secondary: colors.green,
  bodyBackground: colors.grey200,
  borderGrey: colors.grey600
  // fontGrey: colors.grey600,
  // fontDisableGrey: colors.grey400,
  // inputHoverGrey: colors.grey300
};

const button = {
  primary: {
    backgroundDefault: colors.black,
    borderDefault: colors.black,
    colorDefault: colors.white,
    backgroundHover: colors.black,
    borderHover: colors.black,
    colorHover: colors.white
  },
  secondary: {
    backgroundDefault: colors.white,
    borderDefault: colors.grey600,
    colorDefault: colors.black,
    backgroundHover: colors.grey200,
    borderHover: colors.grey600,
    colorHover: colors.black
  },
  tertiary: {
    backgroundDefault: 'transparent',
    borderDefault: colors.grey800,
    colorDefault: colors.black,
    backgroundHover: colors.grey200,
    borderHover: colors.grey800,
    colorHover: colors.black
  }
};

const header = {
  background: colors.black,
  backgroundScrolled: colors.black,
  item: colors.white,
  itemHover: colors.grey200,
  itemScrolled: colors.white
};

const menu = {
  background: colors.white,
  subLevelBackground: colors.white,
  item: colors.black,
  itemBackgroundActive: colors.grey200
};

const cart = {
  badgeBackground: colors.black,
  badgeText: colors.white
};

const main = {
  background: themeColors.bodyBackground
};

const footer = {
  background: colors.black,
  text: colors.white
};

const elementColors = {
  header,
  menu,
  cart,
  main,
  footer,
  button
};

const themeFontSizes = {
  xs: '0.625rem',
  s: '0.812rem',
  base: '1rem',
  xl: '1.250rem',
  '2xl': '1.562rem',
  '3xl': '1.938rem',
  '4xl': '2.438rem',
  '5xl': '3.062rem',
  '6xl': '3.812rem'
};

const space = [
  '0px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px'
];

const breakpoints = {
  xs: '20rem',
  sm: '40rem',
  md: '50rem',
  lg: '64rem',
  xl: '78.75rem'
};

const commonStyles = {
  borderRadius: '4px',
  boxShadow: '0 12px 24px rgb(0 0 0 / 5%)',
  maxWidthText: '47.125rem',
  maxWidth: breakpoints.xl
};

const commonStylesInt = {
  maxWidth: Math.ceil(parseFloat(commonStyles.maxWidth) * 16)
};

const elementSizes = {
  buttonHeight: '54px',
  edgePaddingSizeMu: space[4],
  edgePaddingSizeMd: space[1],
  pagePaddingTop: space[2],
  pagePaddingBottom: space[4],
  flyoutEdgePadding: space[2],
  headerHeightDesktop: '86px',
  headerHeightMobile: '80px'
};

const transitions = {
  background: 'background ease-in-out .2s',
  border: 'border ease-in-out .2s',
  boxShadow: 'box-shadow ease-in-out .2s',
  color: 'color ease-in-out .2s',
  flyout: 'transform cubic-bezier(0.22, 0.35, 0.05, 1) .4s',
  opacity: 'opacity ease-in-out .2s',
  imageScale: 'transform ease-in-out .2s',
  positionBase: 'ease-in-out .2s'
};

const categoryIds = {};

const pageIds = {};

const pageRoutes = {};

module.exports = {
  default: {
    storeName: 'Mopeddelen',
    colors: {
      ...colors,
      ...colorVariants,
      ...themeColors,
      ...elementColors
    },
    themeFontSizes,
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints,
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: space,
    fonts: {
      primary: 'Verdana'
    },
    elementSizes,
    commonStylesInt,
    commonStyles,
    transitions,
    categoryIds,
    pageIds,
    pageRoutes
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black'
    }
  }
};
