import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theme';
import { isRelativeUrl } from '@jetshop/core/helpers/isRelativeUrl';

export const baseStyles = `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${theme.elementSizes.buttonHeight};
  padding: ${theme.space[1]} ${theme.space[3]};
  border-radius: ${theme.commonStyles.borderRadius};
  background-color: ${theme.colors.button.primary.backgroundDefault};
  border: 1px solid ${theme.colors.button.primary.borderDefault};
  color: ${theme.colors.button.primary.colorDefault};
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  transition: ${theme.transitions.color}, ${theme.transitions.background}, ${theme.transitions.border};

  &:hover {
    background-color: ${theme.colors.button.primary.backgroundHover};
    border: 1px solid ${theme.colors.button.primary.borderHover};
    color: ${theme.colors.button.primary.colorHover};
  }

  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }

  :disabled {
    border: 1px solid #dedede;
    opacity: 0.5;
  }

  circle.path {
    color: currentColor;
  }

  ${theme.above.md} {
    max-width: 100%;
  }
`;

export const buttonBaseCSS = css`
  ${baseStyles}
`;

export const buttonSecondary = css`
  background-color: ${theme.colors.button.secondary.backgroundDefault};
  border: 1px solid ${theme.colors.button.secondary.borderDefault};
  color: ${theme.colors.button.secondary.colorDefault};

  &:hover {
    background-color: ${theme.colors.button.secondary.backgroundHover};
    border: 1px solid ${theme.colors.button.secondary.borderHover};
    color: ${theme.colors.button.secondary.colorHover};
  }
`;

export const buttonTertiary = css`
  background-color: ${theme.colors.button.tertiary.backgroundDefault};
  border: 1px solid ${theme.colors.button.tertiary.borderDefault};
  color: ${theme.colors.button.tertiary.colorDefault};

  &:hover {
    background-color: ${theme.colors.button.tertiary.backgroundHover};
    border: 1px solid ${theme.colors.button.tertiary.borderHover};
    color: ${theme.colors.button.tertiary.colorHover};
  }
`;

const linkStylesCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export function TrendLink({ to, className, ...props }) {
  return typeof to === 'string' && !isRelativeUrl(to) ? (
    <a
      className={cx(buttonBaseCSS, linkStylesCSS, className)}
      href={to}
      {...props}
    >
      {props?.children}
    </a>
  ) : (
    <Link
      className={cx(buttonBaseCSS, linkStylesCSS, className)}
      to={to}
      {...props}
    />
  );
}

const StyledSpinner = styled(Spinner)`
  height: 25px;

  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) =>
  loading ? (
    <button {...props} className={cx(props.className, buttonBaseCSS)}>
      <span>{loadingText}</span>
      <StyledSpinner />
    </button>
  ) : (
    <button {...props} className={cx(props.className, buttonBaseCSS)} />
  );

export default ButtonWithLoading;
