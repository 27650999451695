import useAuth from '@jetshop/core/components/AuthContext/useAuth';
// import { cx } from 'linaria';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { styled } from 'linaria/react';
import React from 'react';
// import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import { ReactComponent as Person } from '../../../svg/Person.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
// import { FavouriteCount } from '../../ProductList/FavouriteCount';
import { theme } from '../../Theme';
import MaxWidth from '../MaxWidth';
// import { CategoryMenu } from './CategoryMenu/CategoryMenu';
// import ChannelSelector from './ChannelSelector/ChannelSelector';
// import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { LogoSearchBar } from './LogoSearchBar';
// import { ReactComponent as Logo } from '../../Theme/mopeddelen-logo.svg';
import MobileMenu from './MobileMenu';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import SearchButton from './SearchButton';
import TopNav from './TopNav';
import { Notifications } from '../Notifications';

const MyPagesLink = styled(Link)`
  display: flex;
  align-items: center;
  svg {
    margin: 4px;
    margin-bottom: 0;
    height: 18px;
  }
`;

// const StyledLink = styled(Link)`
//   text-decoration: none;

//   :hover {
//     text-decoration: none;
//   }
// `;

const Container = styled('header')`
  background-color: #fff;
  position: relative;
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }
  ${theme.below.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${theme.elementSizes.headerHeightMobile};
  }

  ${theme.above.md} {
    height: ${theme.elementSizes.headerHeightDesktop};
  }

  .header-button {
    text-decoration: none;
    color: ${theme.colors.header.item};

    &:hover {
      text-decoration: underline;
      color: ${theme.colors.header.itemHover};
    }
  }
  .header-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      svg {
        use {
          fill: ${theme.colors.header.item};
        }
      }
    }
    svg {
      width: 18px;
      height: 20px;
      margin: 0px;
      use {
        fill: ${theme.colors.header.item};
      }
    }
    span {
      font-size: 0.75rem;
    }
    .badge-svg-wrapper {
      height: 20px;
      position: relative;
      .badge {
        position: absolute;
        top: -5px;
        right: -5px;
        color: white;
        font-size: 0.5rem;
      }
    }
  }
  > .open {
    overflow: hidden;
    max-width: calc(100% - 48px);
  }
`;

const HeaderContainer = styled('div')`
  height: ${theme.elementSizes.headerHeightMobile};
  background: ${theme.colors.header.background};
  position: relative;
  z-index: 99;
  width: 100%;
  display: flex;
  align-items: center;

  ${theme.above.md} {
    height: ${theme.elementSizes.headerHeightDesktop};
  }
`;

const HeaderItemsContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;

  svg {
    height: 18px;
    width: 18px;
  }
  :focus,
  :active,
  :hover {
    outline: none;
    opacity: 0.8;
  }
  label {
    margin-top: 3px;
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

function TopHeader() {
  const [searchOpen, setSearchOpen] = React.useState(false);
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  return (
    <HeaderContainer>
      <MaxWidth>
        <HeaderItemsContainer>
          {
            <Above breakpoint="md">
              {matches =>
                matches ? (
                  <TopNav searchOpen={searchOpen} right>
                    <DrawerTrigger preventOverflow={true} id="menu-drawer">
                      {drawer => (
                        <MenuButton
                          onClick={
                            drawer.isOpen
                              ? drawer.hideTarget
                              : drawer.showTarget
                          }
                        >
                          <div className="header-button">
                            <Menu />
                          </div>
                          <label>{t('Products')}</label>
                        </MenuButton>
                      )}
                    </DrawerTrigger>
                    <LogoSearchBar
                      searchOpen={searchOpen}
                      setSearchOpen={setSearchOpen}
                    />
                    <div className="right">
                      {!searchOpen && (
                        <SearchButton
                          className="header-button"
                          setSearchOpen={setSearchOpen}
                          key="searchButtonPosed"
                        />
                      )}

                      {matches && (
                        <MyPagesLink
                          className="header-button"
                          to={routes.myPages.path}
                        >
                          <Person />
                          <span>{loggedIn ? t('My Pages') : t('Log in')}</span>
                        </MyPagesLink>
                      )}
                      {/* <FavouriteCount className="header-button" /> */}
                      <CartButton className="header-button" />
                    </div>
                  </TopNav>
                ) : (
                  <TopNav searchOpen={searchOpen} right>
                    <div className={'logo-link-wrapper'}>
                      <LogoSearchBar
                        searchOpen={searchOpen}
                        setSearchOpen={setSearchOpen}
                      />
                    </div>
                    <div className="mobile-right">
                      {!searchOpen && (
                        <SearchButton
                          className="header-button"
                          setSearchOpen={setSearchOpen}
                          key="searchButtonPosed"
                        />
                      )}

                      {matches && (
                        <MyPagesLink
                          className="header-button"
                          to={routes.myPages.path}
                        >
                          <Person />
                          <span>{loggedIn ? t('My Pages') : t('Log in')}</span>
                        </MyPagesLink>
                      )}
                      {/* <FavouriteCount className="header-button" /> */}
                      <CartButton className="header-button" />

                      <DrawerTrigger preventOverflow={true} id="menu-drawer">
                        {drawer => (
                          <MenuButton
                            onClick={
                              drawer.isOpen
                                ? drawer.hideTarget
                                : drawer.showTarget
                            }
                          >
                            <div className="header-button">
                              <Menu />
                            </div>
                            <label>{t('Menu')}</label>
                          </MenuButton>
                        )}
                      </DrawerTrigger>
                    </div>
                  </TopNav>
                )
              }
            </Above>
          }
          {/* <MobileMenu /> */}
          <CartFlyout />
          <Notifications />
        </HeaderItemsContainer>
      </MaxWidth>
    </HeaderContainer>
  );
}

export default function Header() {
  return (
    <>
      <ChannelBanner />
      <Container>
        <TopHeader />
        <MobileMenu />
        <Notifications />
      </Container>
    </>
  );
}
