import React from 'react';
import { SearchBar } from './SearchBar';
import { Logo } from './Logo';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const LogoSearchBarWrapper = styled('div')`
  width: 100%;
  max-width: 30rem;
  display: flex;
  justify-content: center;
  ${theme.above.md} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
function LogoSearchBar({ searchOpen, setSearchOpen }) {
  return (
    <LogoSearchBarWrapper>
      {searchOpen ? (
        <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
      ) : (
        <Logo searchOpen={searchOpen} />
      )}
    </LogoSearchBarWrapper>
  );
}

export { LogoSearchBar };
