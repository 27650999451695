import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../Theme';

const List = styled('ul')`
  display: flex;
  width: 100%;
  margin-right: 0px;
  justify-content: space-between;
  ${theme.above.md} {
    justify-content: space-between;
  }

  li.top-nav-list-item:last-of-type {
    padding-left: 8px;
    padding-right: 0px;

    .right {
      display: flex;
      .header-button {
        margin-left: ${theme.space[3]};
      }
    }
  }

  &.left {
    margin-right: 8px;
    margin-left: 0px;
    li.top-nav-list-item:first-of-type {
      padding-left: 0px;
      padding-right: 8px;
    }
  }

  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  li.top-nav-list-item {
    list-style: none;
    color: ${theme.colors.header.item};
    display: block;
    white-space: nowrap;

    .mobile-right {
      display: flex;
      align-items: center;
      > button,
      div {
        margin-left: ${theme.space[1]};
      }
    }

    ${theme.above.md} {
      .logo-link-wrapper {
        position: absolute;
        top: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  button {
    background: none;
    color: ${theme.colors.header.item};
  }

  ${theme.below.md} {
    width: '50%';
    &.searchOpen {
      width: 'auto';
    }
  }
`;

export default function TopNav({ children, searchOpen, left, ...rest }) {
  return (
    <List
      className={cx(left ? 'left' : null, searchOpen && 'searchOpen')}
      {...rest}
    >
      {React.Children.map(
        children,
        item => item && <li className="top-nav-list-item">{item}</li>
      )}
    </List>
  );
}
